import React from 'react';
import classnames from 'classnames';

export default ({ className, height = 'auto', ...rest }) => (
    <div
        {...rest}
        style={{ height }}
        className={classnames('text-subdued-lighter', className)}
    >
        Loading...
    </div>
);
