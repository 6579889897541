import { useEffect, memo, useState } from 'react';

import useCreateFindDealerPageEvent from '../../hooks/useCreateFindDealerPageEvent';
import { useRouter } from 'next/router';

const DealerSearchAnalyticsContainer = memo(() => {

    const createFindDealerPageEvent = useCreateFindDealerPageEvent();
    const router = useRouter();
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    // on mount declare a new page
    useEffect(() => {
        if (isFirstLoad) {
            createFindDealerPageEvent({ isInitialLoad: true });
        } else {
            createFindDealerPageEvent();
        }

        setIsFirstLoad(false);
    }, [router.query.slug]);

    return null;
});

export default DealerSearchAnalyticsContainer;
