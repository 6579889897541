import React from 'react';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { useBrand } from 'reaxl-brand';
import { getData } from 'reaxl-schema/es/AutoDealer';
import { connect, useSelector } from 'react-redux';
import Head from 'next/head';
import _get from 'lodash/get';
import { useFeatures } from 'reaxl-features';
import { useOptimizelySnippet } from 'reaxl-optimizely';
import { kbbBrand } from '../constants/index';
import renderOpenGraphMetaTags from './RenderOpenGraphMetaTags';
import { dealerSchemaFormatter } from '../utilities';
import WebVitalsReporter from '../components/WebVitalsReporter';
import { userDuck, dealerMetadataDuck, inventoryMetadataDuck, inventoryActiveOwnerDuck, inventoryFiltersDuck } from '../ducks';

const FacebookMetaTags = dynamic(() => import('./FacebookMetaTags'));

const renderMetadataNoIndex = (noIndex) => (noIndex && (
    <meta
        name="robots"
        content="noindex"
    />
));

const renderCanonicalLink = (noIndex, canonical) => (!noIndex && (
    <link
        rel="canonical"
        href={canonical}
    />
));

const CommonHeadContainer = React.memo(({
    pageName,
    query = {},
    owner,
    makes,
    cityZip,
}) => {
    let ownerWithoutLogo;
    const content = useSelector((state) => {
        switch (pageName) {
            case 'fyd_srl':
                return dealerMetadataDuck.selectors.getDuckState(state);
            case 'fyd_ddp':
                return inventoryMetadataDuck.selectors.getDuckState(state);
            default:
                return _get(state, 'content', {});
        }
    });
    const { description, canonical, noIndex, title } = content;
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const [optimizelySnippet] = useOptimizelySnippet({
        // BONNET TODO: Use this pageName only until Analytics is ready to transition to Bonnet pageNames
        pageName,
        query,
        url: canonical,
    });
    let dealerSchema = {};
    /* eslint-disable */
    if (pageName === 'fyd_ddp') {
        ownerWithoutLogo = Object.assign({}, owner);
        const defaultDealerSchema = getData(ownerWithoutLogo, description);
        dealerSchema = dealerSchemaFormatter(defaultDealerSchema, owner, makes);
    }
    const {
        disable_ads: [NO_ADS],
        disable_thirdparty: [NO_THIRD_PARTY],
    } = useFeatures([
        'disable_ads',
        'disable_thirdparty',
    ]);

    const disableThirdpartyScripts = NO_ADS || NO_THIRD_PARTY || query.NOADSTEST;

    const dealerImages = _get(owner, 'images.sources', []);
    content.dealerImg = dealerImages[0]?.src;

    return (
        <>
            <Script
                strategy="beforeInteractive"
                src="https://unpkg.com/mitt/dist/mitt.umd.js"
            />
            <Script>
                window.CAIEventBus = window.CAIEventBus || window.mitt();
            </Script>
            <Head>
                <title>{title}</title>
                {renderMetadataNoIndex(noIndex)}
                <meta name="description" content={description} />
                <meta name="referrer" content="unsafe-url" />
                {renderCanonicalLink(noIndex, canonical)}
                {pageName === "fyd_ddp" && owner?.id && (
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(dealerSchema)
                        }}
                    />
                )}
                {!disableThirdpartyScripts && optimizelySnippet
                    && <script src={optimizelySnippet} async />
                }
            </Head>
            <WebVitalsReporter enabled={query.enableWebVitals} />
            {renderOpenGraphMetaTags(isKbb, content, title)}
            {isKbb ? <FacebookMetaTags /> : null}
        </>
    );
});

const mapStateToProps = (state) => {
    const owner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const inventoryFilterData = inventoryFiltersDuck.selectors.getDuckState(state);
    const makes = _get(inventoryFilterData, 'options.makeCode.options');
    const cityZip = _get(userDuck.selectors.getLocation(state), "zip", "");

    return {
        owner: state.owners[owner],
        makes,
        cityZip,
    };
};

export default connect(mapStateToProps)(CommonHeadContainer);
