import React, { memo } from 'react';
import { useBrand } from 'reaxl-brand';
import { connect } from 'react-redux';

import dynamic from 'next/dynamic';
import LazyComponent from '../../components/LazyComponent';

import _get from 'lodash/get';
import styled from '@emotion/styled';

import { useDevice } from '@bonnet/next/device';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { DealerResultControlsContainer } from '.';

import { dealerResultsDuck, dealerFiltersDuck, dealerPaginationDuck, userDuck } from '../../ducks';

import { rebuildFindADealerPage } from '../../actions';

import LoadingFallback from '../../components/LoadingFallback';

import { kbbBrand } from '../../constants/index';

const SidebarWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const SidebarMakeSelector = styled.div`
    ${({ lessThanMd }) => `
        order: ${lessThanMd ? 2 : 1};
        margin-top: ${lessThanMd ? '24px' : '0'};
        margin-bottom: ${lessThanMd ? '4px' : '8px'};
    `}
`;

const SidebarFdpqWidget = styled.div`
    max-width: 100%;
    ${({ height }) => `height: ${height};`}
    ${({ lessThanMd }) => `order: ${lessThanMd ? 1 : 2};`}
`;

let filtersOrder = [
    'location',
    'makeCodeList',
    'makeCode',
    'listingTypes',
    'listingType',
];

const getFilterOrder = (filters, isKbb) => {
    if (isKbb) {
        filters = ['location', 'makeCodeList', 'makeCode'];
    }
    return filters;
};

const BuyerConnectContainer = dynamic(() => import('./BuyerConnectContainer'));

const DealersFilterContainer = memo(() => {
    const device = useDevice();
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    filtersOrder = getFilterOrder(filtersOrder, isKbb);
    const lessThanMd = _get(device, 'lessThan.md', false);
    const fallbackFdpqHeight = isKbb ? '418px' : '378px';

    return (
        <div
            className="col-xs-12 col-md-3"
            data-cmp="fyd-fdpq-widget"
        >
            <SidebarWrapper lessThanMd={lessThanMd}>
                <SidebarMakeSelector
                    data-test="make-wrapper"
                    lessThanMd={lessThanMd}
                >
                    <DealerResultControlsContainer />
                </SidebarMakeSelector>
                <SidebarFdpqWidget
                    data-cmp="sidebar-fdpq-widget"
                    height={fallbackFdpqHeight}
                    lessThanMd={lessThanMd}
                >
                    <LazyComponent
                        renderOnScroll={false}
                        fallback={<LoadingFallback height={fallbackFdpqHeight} />}
                    >
                        <BuyerConnectContainer />
                    </LazyComponent>
                </SidebarFdpqWidget>
            </SidebarWrapper>
        </div>
    );
});

function mapStateToProps(state) {
    return {
        resultCount: dealerResultsDuck.selectors.getResultsCount(state),
        filters: dealerFiltersDuck.selectors.getFiltersOptions(state),
        values: dealerFiltersDuck.selectors.getFiltersValues(state),
        isFiltersVisible: dealerFiltersDuck.selectors.getFiltersVisible(state),
        location: state.user.location,
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    applyFilterChange: dealerFiltersDuck.creators.applyFilterChange,
    clearFilter: dealerFiltersDuck.creators.clearFilter,
    hideFilters: dealerFiltersDuck.creators.hideFilters,
    resetPagination: dealerPaginationDuck.creators.resetPagination,
    setUserLocation: userDuck.creators.setLocation,
    rebuildFindADealerPage,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DealersFilterContainer);
