/* global window */
import querystring from 'querystring';
import { pageNames } from '@atc/bonnet-paths';
import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';
import addPageName from '@atc/bonnet-ctx-page-name';

import { getFindDealerQuery, setIsNewPage } from '../context-middleware';

import {
    dealerResultsDuck,
    expiredPageMessageDuck,
    inventoryResultsDuck,
} from '../ducks';

import withBrand from '@atc/bonnet-ctx-brand';

import redirectInternal from '../utilities/redirectInternal';

export function rebuildFindADealerPage(push) {
    return async (dispatch, getState) => {

        // recreate the ctx object passed to getInitialProps;
        const ctx = {
            query: querystring.parse(window.location.search.replace('?', '')),
            store: {
                dispatch,
                getState,
            },
        };

        await withCtxMiddleware([
            withBrand(),
            addPageName(pageNames.DEALER_SEARCH),
            getFindDealerQuery(),
            setIsNewPage(),
        ], ctx);

        // Notify page of loading
        dispatch(dealerResultsDuck.creators.setLoading());

        await redirectInternal(ctx, dispatch, push, { scroll: false });
        dispatch(expiredPageMessageDuck.creators.setFalse());
    };
}

export function rebuildDealerDetailsPage(push) {
    return async (dispatch, getState) => {
        // recreate the ctx object passed to getInitialProps;
        const ctx = {
            query: querystring.parse(window.location.search.replace('?', '')),
            store: {
                dispatch,
                getState,
            },
        };

        await withCtxMiddleware([
            withBrand(),
            addPageName(pageNames.DEALER_DETAILS),
            getFindDealerQuery(),
            setIsNewPage(),
        ], ctx);

        // Notify page of loading
        dispatch(inventoryResultsDuck.creators.setLoading());
        await redirectInternal(ctx, dispatch, push, { scroll: false });
    };
}
