import { useAnalytics, getConsumerMetadata } from 'reaxl-analytics';
import { useStore, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import {
    dealerFiltersDuck,
    dealerPaginationDuck,
    dealerResultsDuck,
    isNewSearchDuck,
} from '../ducks';

export default function useCreateFindDealerPageEvent() {
    const { sendPage, sendEvent } = useAnalytics();
    const store = useStore();
    const dispatch = useDispatch();
    return async ({
        isInitialLoad = false,
        event,
    } = {}) => {
        const state = store.getState();
        const isNewSearch = _get(state, 'isNewSearch');
        const dataIsland = _get(state, 'birf.pageData', {});
        const consumerMetadata = await getConsumerMetadata();
        const data = {
            pageEvents: isNewSearch ? [] : ['fyd_srl_reload'],
            consumerMetadata,
        };
        sendPage({
            data,
            dataIsland,
            domainKey: 'dealersearch',
            isInitialLoad,
            includePageName: isNewSearch,
            filterDuck: dealerFiltersDuck,
            paginationDuck: dealerPaginationDuck,
            resultsDuck: dealerResultsDuck,
            event,
        });
        sendEvent({
            name: 'search',
            type: 'search',
            dataIsland,
        });
        // reset isNewSearch value
        await dispatch(isNewSearchDuck.creators.setValue(true));
    };
}
