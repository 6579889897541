import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import { BuyerConnect } from 'reaxl-organisms';

import getConfig from 'next/config';
import { dealerFiltersDuck, userDuck, dealerCrawlPathDuck, dealerSetPaidSearchIdDuck, dealerSetMakeDuck, birfDuck } from '../../ducks';
import { kbbBrand, atcBrand } from '../../constants';

const BuyerConnectContainer = ({
    urlBase,
    makeCode,
    makelabel,
    listingType,
    brand,
    height,
}) => (
    <BuyerConnect
        urlBase={urlBase}
        makeCode={makeCode}
        makeLabel={makelabel}
        pageName={brand === kbbBrand ? 'rtbdlrlist' : 'fyd_srl'}
        height={height}
        listingType={listingType}
    />
);

const getBuyerConnectUrl = (brand, zip, psid, siteSection, pageType) => {
    const { publicRuntimeConfig } = getConfig();
    let urlBase = (brand === kbbBrand ? `${publicRuntimeConfig.fdpqKbbUrl}&zipcode=${zip}&pagetype=${siteSection}&pageid=${pageType}` : `${publicRuntimeConfig.fdpqAtcUrl}&zipcode=${zip}&pagetype=${siteSection}&pageid=${pageType}`);
    if (psid) {
        urlBase += `&psid=${psid}`;
    }
    return urlBase;
};

const mapStateToProps = (state) => {
    const filterValues = dealerFiltersDuck.selectors.getFiltersValues(state);
    const searchListingType = _get(filterValues, 'listingType', []);

    const listingType = (!searchListingType.length || searchListingType.includes('NEW')) && 'new';
    const brand = _get(state, 'brand', atcBrand);

    const cityZip = _get(userDuck.selectors.getLocation(state), 'zip', '');
    const psid = dealerSetPaidSearchIdDuck.selectors.getDuckState(state);
    const birfData = birfDuck.selectors.getDuckState(state);
    const siteSection = _get(birfData, 'pageData.page.site.section', '');
    const pageType = _get(birfData, 'pageData.page.site.detailedPageName', '');
    const urlBase = getBuyerConnectUrl(brand, cityZip, psid, siteSection, pageType);
    const makeCodeData = _get(filterValues, 'makeCode', []);
    const makeCodeList = typeof makeCodeData === 'string' ? makeCodeData.split(',') : makeCodeData;
    const height = brand === kbbBrand ? '418px' : '378px';

    let makeCode = '';
    let makeLabel = '';
    if (makeCodeList.length === 1) {
        [makeCode] = makeCodeList;
        makeLabel = makeCode ? _get(dealerFiltersDuck.selectors.getLabelsByValues(state), ['makeCode', makeCode]) : '';
    }

    makeLabel = dealerSetMakeDuck.selectors.getDuckState(state);
    const crawlPathData = dealerCrawlPathDuck.selectors.getDuckState(state);
    const makeCodes = _get(crawlPathData, 'makeCodes', []);
    const foundMake = makeCodes.find((mc) => mc.name.toLowerCase() === makeLabel.toLowerCase());
    makeCode = _get(foundMake, 'code', '');

    return {
        urlBase,
        makeCode,
        makeLabel,
        listingType,
        brand,
        height,
    };
};

export default connect(mapStateToProps)(BuyerConnectContainer);
