// FCP, LCP, TTFB & CLS fire on page load - INP & FID fire on interaction
// filter console on 'web-vitals' to see data
import { useEffect } from 'react';

import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';

export default function WebVitals({ enabled }) {

    useEffect(() => {
        if (enabled) {
            const webVitalsFunctions = [onCLS, onFCP, onFID, onINP, onLCP, onTTFB];
            webVitalsFunctions.forEach((func) => {
                func((metric) => {
                    // eslint-disable-next-line no-console
                    console.log(`web-vitals ${metric?.name} metric:`, metric);
                }, { reportAllChanges: true });
            });
        }
    }, [enabled]);
}
