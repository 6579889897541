import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { cloneDeep } from 'lodash';
import { Breadcrumbs, Link } from 'reaxl';
import { breadcrumbsForFindDealer, allMakeValue } from '../../constants';
import { dealerSetMakeDuck, dealerMetadataDuck } from '../../ducks';

const getBreadcrumbsForFindDealer = (make, asPath) => {
    if (make && make !== allMakeValue && asPath) {
        const breadcrumbsWithMade = cloneDeep(breadcrumbsForFindDealer);
        const seoMakeCode = make.toLowerCase().replace(' ', '-');
        breadcrumbsWithMade.find((item) => item.label === 'Dealers')
            .href = asPath.replace(`/${seoMakeCode}`, '');
        breadcrumbsWithMade.push({
            label: make,
            href: '',
        });
        return breadcrumbsWithMade;
    }
    return breadcrumbsForFindDealer;
};

const DealerSearchBreadcrumbs = ({
    fldBreadcrumbs,
    kbbBreadcrums,
}) => {
    const breadcrumbs = fldBreadcrumbs;

    const getBreadcrumbsData = () => breadcrumbs.reduce((acc, crumb) => {
        if (crumb.label) {
            acc.push(crumb.label);
        }
        return acc;
    }, []);

    const customRenderBreadcrumb = (crumb, i, arr) => {
        if (breadcrumbs[i].href.length > 0) {
            return (
                <Link
                    key={crumb}
                    className="text-link text-size-200 display-inline-block"
                    href={breadcrumbs[i].href}
                >
                    {arr[i]}
                </Link>
            );
        }
        return (
            <span
                key={crumb}
                className="text-gray text-size-200 display-inline-block"
            >
                {arr[i]}
            </span>
        );
    };

    return (
        <div className="container container-fluid padding-top-24">
            <Breadcrumbs
                id="breadcrumbs-container"
                items={getBreadcrumbsData()}
                render={customRenderBreadcrumb}
                className=""
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    const make = dealerSetMakeDuck.selectors.getDuckState(state);
    const dealerMetadata = dealerMetadataDuck.selectors.getDuckState(state);
    const asPath = _get(state, 'router.location.pathname') + _get(state, 'router.location.search');
    const fldBreadcrumbs = _get(dealerMetadata, 'fldBreadcrumbs', []);
    const kbbBreadcrums = getBreadcrumbsForFindDealer(make, asPath);

    return {
        fldBreadcrumbs,
        kbbBreadcrums,
    };
};

export default connect(mapStateToProps)(DealerSearchBreadcrumbs);
