import React, {
    useEffect,
} from 'react';
import { useStore } from 'react-redux';
import { useBrand } from 'reaxl-brand';
import dynamic from 'next/dynamic';

import { useOptimizelyTrack } from 'reaxl-optimizely';
import { pageNames } from '@atc/bonnet-paths';

import {
    DealerCrawlPathLocationContainer,
    DealerCrawlPathMakesContainer,
    BodyFLDContainer,
} from '../../containers/dealersearch';
import { atcBrand, kbbBrand, PAGE_NAME } from '../../constants/index';
import { AnalyticsProvider } from 'reaxl-analytics';
import useAnalyticsProps from '../../analytics/useAnalyticsProps';
import { useFeatures } from 'reaxl-features';
import { queryDuck, currentPageNameDuck } from '../../ducks';

const PersonalizationEngineContainer = dynamic(() => import('../../containers/PersonalizationEngineContainer'), { ssr: false });

const FindADealerPage = () => {
    const store = useStore();
    const state = store.getState();
    const query = queryDuck.selectors.getDuckState(state);
    const pageName = currentPageNameDuck.selectors.getDuckState(state);
    const optimizelyTrack = useOptimizelyTrack();
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const { disable_personalization_engine: [isPersonalizationEngineDisabled] } = useFeatures(['disable_personalization_engine']);
    const analyticsProps = useAnalyticsProps();

    useEffect(() => {
        optimizelyTrack('fyd_srl_visit');
        optimizelyTrack('global_pageview');
        return () => {
            optimizelyTrack('fyd_srl_exit');
        };
    }, []);

    return (
        <>
            {!isPersonalizationEngineDisabled && (
                <PersonalizationEngineContainer
                    pageName={PAGE_NAME.FYD}
                    brand={isKbb ? kbbBrand : atcBrand}
                />
            )}
            {pageName === pageNames.DEALER_SEARCH && (
                <AnalyticsProvider {...analyticsProps}>
                    <BodyFLDContainer
                        isKbb={isKbb}
                        query={query}
                    />
                    <div className="container container-fluid">
                        <div className="row margin-vertical-3">
                            <div
                                className="col-xs-12"
                                data-cmp="dealerCrawlPathSection"
                            >
                                <DealerCrawlPathLocationContainer />
                                <DealerCrawlPathMakesContainer />
                            </div>
                        </div>
                    </div>
                </AnalyticsProvider>
            )}
        </>
    );
};

export default FindADealerPage;
