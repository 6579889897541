import React, { memo, useState, useEffect } from 'react';
import { useBrand } from 'reaxl-brand';
import { connect } from 'react-redux';
import { withRouter, useRouter } from 'next/router';
import _get from 'lodash/get';
import { FilterLocationModalTrigger } from 'reaxl-filters';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { getMarket } from '@atc/bonnet-reference';
import {
    dealerFiltersDuck,
    dealerPaginationDuck,
    userDuck,
} from '../../ducks';
import { rebuildFindADealerPage } from '../../actions';
import { kbbBrand } from '../../constants';
import styled from '@emotion/styled';
import { useDevice } from '@atc/react-device';

const CustomZipText = styled.div`
    
    ${({ isKbb }) => (!isKbb ? 'margin-bottom: 5px;;font-size: 18px !important;.text-link .text-link { font-size: 18px;}' : '')};
`;
const getModalFilters = (values) => Object.keys(values).filter((key) => {
    if (key === 'zip' || key === 'searchRadius') {
        return values[key];
    }
    return '';
});

const getErrorMessage = (message) => {
    if (message.includes('cannot find zipcode data')) {
        return 'Enter a valid ZIP Code';
    }
    if (message.includes('argument is missing or invalid')) {
        return 'ZIP Code is missing or invalid';
    }
    return message;
};

const FindADealerFilterLocationModalContainer = memo(({
    actions,
    filtersErrors,
    filtersOptions,
    filtersValues,
    userLocation,
}) => {
    const router = useRouter();
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const [values, setValues] = useState(filtersValues);
    const [filtersUpdated, setFiltersUpdated] = useState(false);
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    useEffect(() => {
        setFiltersUpdated(true);
        setValues(filtersValues);
    }, [filtersUpdated]);

    const handlePageUpdate = async () => {
        actions.resetPagination();
        await actions.rebuildFindADealerPage(router.push);
    };

    const handleZipUpdate = async (event, filter) => {
        // Handle zip validation
        if (filter.name === 'zip') {
            const { success, message, payload: { zip, city, state } = {} } = await getMarket(event.target.value);
            if (!success) {
                const errorMsg = getErrorMessage(message);
                actions.setFilterError({ errorMessage: errorMsg, filter });
                return;
            }
            // temp fix for error form zip 99999 of getMarket - need remove if apply other solution
            if (zip === '99999') {
                const errorMsg = getErrorMessage('Enter a valid ZIP Code');
                actions.setFilterError({ errorMessage: errorMsg, filter });
                return;
            }
            actions.setUserLocation({ ...userLocation, city, state });
            setValues({ ...userLocation, zip });
        }
        actions.removeFilterError({ filter });
    };

    const handleOptionChange = (event, filter) => {

        event.persist();

        switch (filter.name) {
            case 'zip': {
                handleZipUpdate(event, filter);
                break;
            }

            default:
                actions.applyFilterChange(event, filter);
                break;
        }

    };

    const handleHide = (event, submitted = false) => {
        if (!submitted) {
            // revert filter selections to previous values so we don't keep the wrong values
            const modalFilters = getModalFilters(values);

            const filters = modalFilters.map((key) => {
                const filter = filtersOptions[key];
                return {
                    filter: {
                        ...filter,
                    },
                    value: (filter?.name === 'zip') ? userLocation?.zip : values[key],
                };
            });

            actions.selectFilters(filters);
        }
        actions.removeFilterError({ filter: filtersOptions.zip });
        setFiltersUpdated(false);
    };

    const handleLocationModalSubmit = (event, onHide) => {
        const hasError = _get(filtersErrors, 'zip', false);

        if (!hasError) {
            const { zip = '' } = values;
            // do not apply change if zip code is unchanged
            if (zip !== userLocation?.zip) {
                actions.setUserLocation({ ...userLocation, zip });
                actions.applyFilterChange({ ...event, target: { value: zip } }, filtersOptions.zip);
                handlePageUpdate();
            }
            onHide(event, true);
        }
    };

    return (
        <CustomZipText
            isKbb={isKbb}
            className={`margin-left-2 ${!isKbb && isXs ? '' : 'display-inline'}`}
        >
            <FilterLocationModalTrigger
                filters={filtersOptions}
                onOptionChange={handleOptionChange}
                onHide={handleHide}
                onSubmit={handleLocationModalSubmit}
                values={filtersValues}
                errors={filtersErrors}
            />
        </CustomZipText>
    );
});

function mapStateToProps(state) {
    const filtersOptions = dealerFiltersDuck.selectors.getFiltersOptions(state);
    delete filtersOptions.searchRadius;
    return {
        filtersOptions,
        filtersValues: dealerFiltersDuck.selectors.getFiltersValues(state),
        filtersErrors: dealerFiltersDuck.selectors.getFiltersErrors(state),
        userLocation: userDuck.selectors.getLocation(state),
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    applyFilterChange: dealerFiltersDuck.creators.applyFilterChange,
    resetPagination: dealerPaginationDuck.creators.resetPagination,
    rebuildFindADealerPage,
    selectFilters: dealerFiltersDuck.creators.selectFilters,
    removeFilterError: dealerFiltersDuck.creators.removeError,
    setFilterError: dealerFiltersDuck.creators.addError,
    setUserLocation: userDuck.creators.setLocation,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FindADealerFilterLocationModalContainer));
