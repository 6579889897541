import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { CollapsibleListColumns } from 'reaxl-molecules';
import { useAnalytics } from 'reaxl-analytics';
import { dealerCrawlPathDuck } from '../../ducks';

const DealerCrawlPathLocationContainer = ({
    items,
}) => {
    const { sendClick } = useAnalytics();
    return (
        <CollapsibleListColumns
            title="Car Dealers by City"
            items={items}
            render={({ label, ...rest }) => (
                <a
                    onClick={(event) => sendClick('sitemapFilterClick', event, { sitemapContent: event.target.innerText })}
                    {...rest}
                >
                    {label}
                </a>
            )}
            xs={2}
            md={4}
            lg={5}
        />
    );
};

function mapStateToProps(state) {
    const crawlPathData = dealerCrawlPathDuck.selectors.getDuckState(state);
    return {
        items: get(crawlPathData, 'locations', []),
    };
}

export default connect(mapStateToProps)(DealerCrawlPathLocationContainer);
