/**
 * Gets the String value to display the results count
 * @param {integer} resultCount
 * @returns a string value
 */
const getResultCountText = (resultCount) => {
    if (resultCount && resultCount > 0) {
        return `Showing ${resultCount} ${resultCount === 1 ? 'result' : 'results'}`;
    }
    return 'Showing 0 results';
};

export default getResultCountText;
