import React from 'react';
import Head from 'next/head';

const renderOpenGraphMetaTags = (isKbb, content, title) => {
    const logo = isKbb ? 'https://file.kbb.com/kbb/images/kbb/kelley-blue-book.jpg' : '';

    const openGraph = {
        title,
        url: content.canonical,
        image: content.dealerImg || logo,
        description: content.description,
        site_name: isKbb ? 'Kbb.com' : 'Autotrader.com',
    };

    const openGraphTags = Object.keys(openGraph).map((key) => (
        openGraph[key] ? (
            <meta
                key={key}
                property={`og:${key}`}
                content={openGraph[key]}
            />
        ) : null
    ));

    return (
        <Head>
            {openGraphTags}
        </Head>
    );
};

export default renderOpenGraphMetaTags;
