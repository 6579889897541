import React from 'react';

import {
    FindADealerHeadingContainer,
    DealerSearchAnalyticsContainer,
} from '.';
import { PAGE_NAME } from '../../constants/index';
import CommonHeadContainer from '../CommonHeadContainer';

const HeaderFLDContainer = React.memo(({ query }) => (
    <div className="container container-fluid">
        <DealerSearchAnalyticsContainer />
        <CommonHeadContainer
            pageName={PAGE_NAME.FYD}
            query={query}
        />
        <div className="row">
            <div className="col-xs-12">
                <div className="row">
                    <div
                        className="col-xs-12 col-md-6 margin-top-0 margin-bottom-2 margin-bottom-md-0"
                        style={{ width: '100%' }}
                    >
                        <FindADealerHeadingContainer />
                    </div>
                </div>
            </div>
        </div>
    </div>

));

export default HeaderFLDContainer;
