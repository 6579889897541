import React, { memo } from 'react';
import { ColoredBackground } from 'reaxl';
import {
    DealerSearchResultsContainer,
    DealerFiltersContainer,
    DealerSearchBreadcrumbs,
    HeaderFLDContainer,
} from '.';
import styled from '@emotion/styled';

const CommonDiv = styled.div`
    ${({ isKbb }) => ((isKbb !== true) ? 'margin-top: 5px;' : '')};
`;
const BodyFLDContainer = memo(({ isKbb, query }) => {
    const commonLayout = () => (
        <CommonDiv
            isKbb={isKbb}
            className="container container-fluid"
        >
            <div className="row margin-vertical-3">
                <DealerFiltersContainer />
                <div className="col-xs-12 col-md-9 search-results-container">
                    <DealerSearchResultsContainer />
                </div>
            </div>
        </CommonDiv>
    );

    return (
        <ColoredBackground>
            <DealerSearchBreadcrumbs />
            <HeaderFLDContainer
                query={query}
            />
            {commonLayout()}
        </ColoredBackground>
    );

});

export default BodyFLDContainer;
